*, *:before, *:after {box-sizing: border-box;}
.form {
  background: #fff;    
  width: 420px;
  margin: 80px auto;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  height: auto;
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12)
}
.form form {
  padding: 40px;    
}
.logo {
  margin: 0;
  padding: 30px;
  position: relative;
  border-bottom: 1px solid #ddd;
  z-index: 10;
}
.logo img {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  max-height: 40px
}
.form label {
  position: absolute;
  -webkit-transform: translateY(12px);
          transform: translateY(12px);
  left: 13px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  backface-visibility: hidden;
  pointer-events: none;
  font-size: 14px;
}
label .req {
  margin: 2px;
  color: rgba(183, 208, 238, 0.95);
}

label.active {
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  left: 2px;
  font-size: 14px;
}
label.active .req {
  opacity: 0;
}

label.highlight {
  color: #1861e6;
}

.form input {
  font-size: 18px;
  line-height: 30px;
  display: block;
  width: 100%;
  padding: 5px 10px;
  background: none;
  background-image: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color:rgba(185, 202, 223, 0.95);
  -webkit-transition: border-color .25s ease, box-shadow .25s ease;
  transition: border-color .25s ease, box-shadow .25s ease;
}
.form input:focus {
  border-width: 1px;
  outline: 0;
  color: black;
  border-image-source: linear-gradient(115deg, #6caaff, #1861e6);
  border-image-slice: 1;
}
.field-wrap {
  position: relative;
  margin-bottom: 20px;
}  
.form .button {
  border: 0;
  outline: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .1em;
  background: #2d4e77;
  color: #ffffff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-appearance: none;
}
.form .button:hover {
  background-color: #fab03c; 
  color: #2d4e77;
  cursor:pointer;  
}
.button-block {
  display: block;
  width: 100%;
}
.field-wrap p {
  margin-top: 35px;
  font-size: 12px;
  line-height: 1.5;
}