.homePage {
  .accountDetail {
    &-credits {
      line-height: 24px;
      .credits {
        text-align: end;
      }
    }
  }
  .NewAnimationTile {
    max-height: 70px;
    cursor: pointer;
  }
}
