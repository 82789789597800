.previewerLabeling {
  max-width: 960px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px var(--dm-brand);
  .LearnMore {
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid var(--dm-brand);
  }
  .rerunLabel {
    color: #8990ab;
    background-color: #62a5f71c;
  }
}
