.motionLabeling {
  .LearnMore {
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #ffffff;
  }
  &-textarea {
    position: relative;
    .textarea {
      background: #4d6080;
      color: #ffffff;
      border: none;
      resize: unset;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: #c0c0c0;
      }
    }
    .textLenght {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}
