.MultiPersonSelector-modal {
  &.modal {
    overflow: hidden;
    background-color: #0000008c;
  }

  .modal-dialog {
    height: 80%;
  }

  .modal-content {
    height: 100%;
    background-clip: padding-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    margin-top: 30px;

    .modal-header {
      background-color: #354d74;
      color: white;
      width: 100%;
      border-radius: 0px;
      border-bottom: var(--bs-modal-header-border-width) solid
        var(--bs-modal-header-border-color);
      border-top-left-radius: var(--bs-modal-inner-border-radius);
      border-top-right-radius: var(--bs-modal-inner-border-radius);
      flex-shrink: 0;
      padding: 16px;
      .btn-close {
        border: 1px solid #2d3041;
        background-color: #2d3041;
        opacity: 1;
        border-radius: 30px;
        background-image: none;
        position: relative;
        display: inline-block;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: #2d3041a3;
        }
        &::after {
          content: "X";
          position: absolute;
          top: 46%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 22px;
          color: white;
          font-weight: 800;
        }
      }
      .h4 {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .detail {
      .title {
        color: #354d74;
      }
      .subtitle {
        color: #354d74;
      }

      &-swiper {
        &-centent {
          background: #ccd4e3;
          width: 800px;
          box-shadow: inset 0px 0px 10px 5px #a6b8d9;

          .swiper-slide {
            &:nth-child(3n - 1) {
              .character {
                filter: grayscale(1);
                &.check {
                  filter: grayscale(0);
                  border-color: #d2875f;
                }
                &-persons {
                  .head {
                    background: #d2875f;
                  }
                }
                &-model {
                  .head {
                    background: #d2875f;
                  }
                  .selection {
                    background-image: linear-gradient(to top, #762700, #ffbfa0);
                  }
                }
              }
            }

            &:nth-child(3n) {
              .character {
                &.check {
                  border-color: #c74f50;
                }
                &-persons {
                  .head {
                    background: #c74f50;
                  }
                }
                &-model {
                  .head {
                    background: #c74f50;
                  }
                  .selection {
                    background-image: linear-gradient(to top, #9f0e0e, #e38080);
                  }
                }
              }
            }
          }

          .character {
            box-sizing: border-box;
            border: 8px solid #ccd4e300;
            border-radius: 5px;
            transition: all 0.3s;
            position: relative;
            padding-bottom: 30px !important;
            filter: grayscale(1);
            &.check {
              filter: grayscale(0);
              border: 8px solid #5eb4e5;
            }
            &-persons {
              .head {
                background: #5eb4e5;
                border-radius: 5px;
                color: #fff;
              }
            }
            &-model {
              .head {
                background: #5eb4e5;
                border-radius: 5px;
                color: #fff;
                .icon {
                  width: 24px;
                }
              }
              .model {
                width: 100%;
                height: 213px;
                overflow: hidden;
                background: #ffffff;
                display: flex;
              }
              .selection {
                position: absolute;
                bottom: 0;
                z-index: 99;
                width: 100%;
                left: 0;
                line-height: 35px;
                border-radius: 20px;
                color: #ffffff;
                box-shadow: 0px 0px 10px 2px #424242;
                background-image: linear-gradient(to top, #0e699f, #8dbfdd);
              }
            }
          }
        }

        &-scrollbar {
          margin-top: 10px;
          position: absolute;
          left: 50%;
          height: 15px;
          transform: translateX(-50%);
          width: 750px;
          background-color: #ededed;
          border-radius: 15px;

          .swiper-scrollbar-drag {
            margin-left: 0;
            box-shadow: 0px 0px 10px 2px rgb(255 255 255 / 50%);
            background: #c3c3c3;
            height: 20px;
            top: -2px;
          }
        }

        .swiper-navigation {
          visibility: visible !important;
          height: auto !important;
          cursor: pointer;
          color: var(--dm-brand-alt);
          text-shadow: 0px 2px 8px #898989;
          &.notEnabled {
            color: #cccccc;
          }
        }
      }
    }

    .modal-footer {
      border: none;
      padding: 0px 34px;
      margin: 20px 0px 20px;
      margin-top: 50px;
      display: flex;
      justify-content: center;

      button {
        background-color: #a5a5a5 !important;
        border: none !important;
        color: white;
        font-weight: 600;
        border: 1px solid #354d74;
        transition: all 0.3s ease;
        z-index: 100;
        opacity: 1;
        margin: 0px;
        text-transform: none;

        &.active {
          background-color: #354d74 !important;

          &:hover {
            color: #f0b454 !important;
          }
        }
      }
    }
  }
}

.CharacterSelectionDialog {
  z-index: 49;
}