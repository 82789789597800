.cancellation-modal {
  &.modal {
    overflow: hidden;
    background-color: #0000008c;
  }
  .modal-dialog {
    height: 80%;
  }
  .modal-content {
    height: 100%;
    background-clip: padding-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    margin-top: 30px;
    .modal-header {
      background-color: #354d74;
      color: white;
      width: 100%;
      border-radius: 0px;
      border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
      border-top-left-radius: var(--bs-modal-inner-border-radius);
      border-top-right-radius: var(--bs-modal-inner-border-radius);
      flex-shrink: 0;
      padding: 16px;
      .h4{
        font-size: 1.5rem;
        font-weight: 600;
      }
      .btn-close{
        border: 1px solid #2d3041;
        background-color: #2d3041;
        opacity: 1;
        border-radius: 30px;
        background-image: none;
        position: relative;
        display: inline-block;
        transition: all .3s ease-in-out;
        cursor: pointer;
        &:hover{
          background-color: #2d3041a3;
        }
        &::after {
          content: "X";
          position: absolute;
          top: 46%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 22px;
          color: white;
          font-weight: 800;
        }
      }
    }
    .modal-body {
      overflow: auto;
      padding: 1.5rem;
      .header {
        h2 {
          line-height: 1.5;
        }
        .detail {
          color: #555;
          line-height: 1.3;
        }
        padding-bottom: 10px;
        border-bottom: 1px dotted #ccc;
      }
      .question {
        .subtitle {
          color: #222;
        }
        .select-content {
          color: #444;
          .item {
            display: block;
            span {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .modal-footer {
      border: none;
      padding: 0px 34px;
      margin: 20px 0px 20px;
      display: flex;
      justify-content: flex-end;
      button {
        background-color: #a5a5a5 !important;
        border: none !important;
        color: white;
        font-weight: 600;
        border: 1px solid #354d74;
        transition: all 0.3s ease;
        z-index: 100;
        opacity: 1;
        margin: 0px;
        text-transform: none;
        &.active {
          background-color: #354d74 !important;
          &:hover {
            color: #f0b454 !important;
          }
        }
      }
    }
  }
}