.MultiPersonSelector {
  position: relative;

  &-mark {
    position: absolute;
    color: var(--dm-brand-alt);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 35;
    background-color: rgba($color: #121212, $alpha: 0.4);
    &-tips {
      margin-top: 180px;
      &.load {
        margin-top: 130px;
      }
    }
  }

  &-selection {
    position: relative;
    background: rgba($color: #50617f, $alpha: 1);
    .swiper-navigation {
      visibility: visible !important;
      height: auto !important;
      cursor: pointer;
      color: var(--dm-brand-alt);
      text-shadow: 0px 2px 8px #898989;
      width: 30px;
      &.notEnabled {
        color: #ffffff;
      }
      &.swiper-prev {
        left: 0;
        margin-right: 20px;
      }

      &.swiper-next {
        right: 0;
        margin-left: 20px;
      }
    }

    &-swiper {
      padding: 0 30px;
      box-sizing: border-box;

      .swiper-slide {
        &:nth-child(3n - 1) {
          .slide-box {
            &.show {
              border: 2px solid #d2875f;
            }
          }
        }
        &:nth-child(3n) {
          .slide-box {
            &.show {
              border: 2px solid #c74f50;
            }
          }
        }

        .slide-box {
          border: 2px solid #50617f;
          filter: grayscale(1);
          width: 100%;
          height: 100%;
          &.show {
            filter: grayscale(0);
            border: 2px solid #5eb4e5;
          }
          .slide-img {
            cursor: pointer;
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      .unavailable {
        width: 130px;
      }
    }

    .swiper-scrollbar {
      margin-top: 10px;
      position: absolute;
      left: 50%;
      height: 15px;
      transform: translateX(-50%);
      width: 480px;
      background-color: #ffffff;

      .swiper-scrollbar-drag {
        margin-left: 0;
        box-shadow: 0px 0px 10px 2px rgb(255 255 255 / 50%);
        background: #ffffff;
        height: 20px;
        top: -2px;
      }
    }
  }

  &-footer {
    .button {
      width: 100%;
    }

    .action-btn {
      background: #dbdbdb !important;
    }
  }
  .loadEffect {
    width: 100px;
    height: 100px;
    position: relative;
    margin: 0 auto;
  }
  .loadEffect span {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--dm-brand-alt);
    position: absolute;
    animation: load 1.04s ease infinite;
  }
  @keyframes load {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  .loadEffect span:nth-child(1) {
    left: 0;
    top: 50%;
    margin-top: -8px;
    animation-delay: 0.13s;
  }
  .loadEffect span:nth-child(2) {
    left: 14px;
    top: 14px;
    animation-delay: 0.26s;
  }
  .loadEffect span:nth-child(3) {
    left: 50%;
    top: 0;
    margin-left: -8px;
    animation-delay: 0.39s;
  }
  .loadEffect span:nth-child(4) {
    top: 14px;
    right: 14px;
    animation-delay: 0.52s;
  }
  .loadEffect span:nth-child(5) {
    right: 0;
    top: 50%;
    margin-top: -8px;
    animation-delay: 0.65s;
  }
  .loadEffect span:nth-child(6) {
    right: 14px;
    bottom: 14px;
    animation-delay: 0.78s;
  }
  .loadEffect span:nth-child(7) {
    bottom: 0;
    left: 50%;
    margin-left: -8px;
    animation-delay: 0.91s;
  }
  .loadEffect span:nth-child(8) {
    bottom: 14px;
    left: 14px;
    animation-delay: 1.04s;
  }
}
