.wrap {
  perspective: 1000px;
  perspective-origin: 50% 50%;

  /*  Change this to adjust the 3d bar tilting */
  /*transform: rotateZ(0.01turn) rotateY(0.01turn);*/
}
.progress-3d
{
  height: 100%;
  width: 0%;
  padding: 0px;
  transition: width 2s ease;
  
  /* make bar grow from left side */
  margin-left: 0; 

  overflow: visible;
  text-align: center;
  color: #000;
  font-family: verdana;
  font-size: 20px;
  white-space: nowrap;
  line-height: 45px;
  
  border-right: solid 2px #444;
  background-size: 50px 50px;
  /* Branded pinstripes */
  background-image: linear-gradient(135deg,#fab03c 25%,#ffd18c 25%,#ffd18c 50%, #fab03c 50%, #fab03c 75%,#ffd18c 75%,#ffd18c 100%);
  animation: bganim 1s linear 2s infinite;
}
@keyframes bganim {
  to { background-position: 50px;}
}
.cube {
  margin: auto;
  position: relative;
  height: 50px;
  width: 95% !important;
  transform-style: preserve-3d;
  animation: rotate 20s infinite linear;
}

.cube > div {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: solid 1px #eee;
  background-size: 50px 50px;
  /* Grey stripes */
  background-image: linear-gradient(135deg,#ddd 25%,#eee 25%,#eee 50%, #ddd 50%, #ddd 75%,#eee 75%,#eee 100%);
  animation: bganim 1s linear 2s infinite;
}
.cube > .left,.cube > .right{
  height: 50px;
  width: 50px;
}

.front {
  transform: translateZ(25px);
}

.back {
  transform: translateZ(-25px) rotateX(180deg);
}

.top {
  transform: rotateX(-270deg) translateY(-25px);
  transform-origin: top center;
}

.bottom {
  transform: rotateX(270deg) translateY(25px);
  transform-origin: bottom center;
}

.left {
  transform: rotateY(270deg) translateX(-25px);
  transform-origin: center left;
}
.right {
  transform: rotateY(-270deg) translateX(25px) translateZ(450px);
  transform-origin: top right;
}

/* Rotating the 3d rectangle */
@keyframes rotate {
  100%{
    transform: rotateX(1turn);
  }
}