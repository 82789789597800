.MultiPersonSelectorDownloadDialog {
  padding-bottom: 50px;
  .title {
    color: #354d74;
  }
  .subtitle {
    color: #354d74;
    font-style: italic;
  }
  .jobtype-slider {
    background-image: linear-gradient(#cccccc, #ddd);
    border: none;
    &::before {
      bottom: 4px;
    }
    &.useTPose {
      background-image: linear-gradient(#fab03c, #ffd28b);
    }
  }
  .detail {
    &-swiper {
      &-centent {
        background: #ccd4e3;
        width: 800px;
        box-shadow: inset 0px 0px 10px 5px #a6b8d9;
        .swiper-slide {
          padding: 10px;
          &:nth-child(3n - 1) {
            .characterBox {
              border-color: #d2875f;
              .head {
                background: #d2875f;
              }
            }
          }

          &:nth-child(3n) {
            .characterBox {
              border-color: #c74f50;
              .head {
                background: #c74f50;
              }
            }
          }
          .characterBox {
            border: 8px solid #5eb4e5;
            border-radius: 5px;
            .head {
              background: #5eb4e5;
              border-radius: 5px;
              color: #fff;
            }
            .characterThumbnail {
              width: 200px;
            }
            .characterModel {
              .characterThumbnail {
                vertical-align: bottom;
                background-color: #ffffff;
              }
            }
          }
        }
      }
      &-scrollbar {
        margin-top: 10px;
        position: absolute;
        left: 50%;
        height: 15px;
        transform: translateX(-50%);
        width: 750px;
        background-color: #ededed;
        border-radius: 15px;
        .swiper-scrollbar-drag {
          margin-left: 0;
          box-shadow: 0px 0px 10px 2px rgb(255 255 255 / 50%);
          background: #c3c3c3;
          height: 20px;
          top: -2px;
        }
      }
      .swiper-navigation {
        visibility: visible !important;
        height: auto !important;
        cursor: pointer;
        color: var(--dm-brand-alt);
        text-shadow: 0px 2px 8px #898989;
        &.notEnabled {
          color: #898989;
        }
      }
    }
  }
  .modal-footer {
    border: none;
    padding: 0px 34px;
    margin: 20px 0px 20px;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: #a5a5a5 !important;
      border: none !important;
      color: white;
      font-weight: 600;
      border: 1px solid #354d74;
      transition: all 0.3s ease;
      z-index: 100;
      opacity: 1;
      margin: 0px;
      text-transform: none;
      &.active {
        background-color: #354d74 !important;
        &:hover {
          color: #f0b454 !important;
        }
      }
    }
  }
}
