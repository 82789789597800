// styling
.deepmotion-body {
  .video-main-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-box {
      width: 100%;
      height: 350px;
      margin: 0 auto;
      max-width: 600px;

      video {
        width: 100%;
        height: 350px;
        object-fit: revert;
        margin: 0 auto;
      }
    }

    .file-drop-target {
      border: 3px solid #354d74;
      padding: 10px;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .crop-video-btn {
      background-color: #354d74 !important;
      border: none !important;
      color: white;
      font-weight: 600;
      border: 1px solid #354d74;
      transition: all 0.3s ease;
      opacity: 1;

      &:hover {
        background-color: #354d74e5 !important;
        color: #ffffff !important;
      }
    }

    .reatake-btn {
      display: flex;
      justify-content: end;
      align-items: center;
    }

    .file-drop {
      width: 65%;
      height: auto;
      margin: 0 auto;
      padding: 0px 0px;
    }
  }

  .video-modal {
    .modal-content {
      .modal-header {
        background-color: #354d74;
        color: white;
        width: 100%;
        border-radius: 0px;

        .btn-close {
          border: 1px solid #2d3041;
          background-color: #2d3041;
          opacity: 1;
          border-radius: 30px;
          background-image: none;
          position: relative;
          display: inline-block;
          transition: all .3s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: #2d3041a3;
          }
        }
      }

      .modal-footer {
        border: none;
        padding: 0px 34px;
        margin: 20px 0px 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          background-color: #354d74 !important;
          border: none !important;
          color: white;
          font-weight: 600;
          border: 1px solid #354d74;
          transition: all 0.3s ease;
          z-index: 100;
          opacity: 1;
          margin: 0px;

          &:hover {
            background-color: #354d74 !important;
            color: #f0b454 !important;
          }
        }
      }
    }

    .current-time {
      font-size: 20px;
      font-weight: bold;
      display: block;
      top: 45px;
      position: absolute;
      color: #354d74;
      margin: 0 -1px;
      transform: rotate(90deg);
      font-size: 15px;
    }

    .video-frame {
      margin-top: 0px;
      border: 15px solid #354d74;
      border-left: 36px solid #354d74;
      border-right: 44px solid #354d74;
      border-radius: 14px;
    }

    .frame-content {
      margin: 10px 0px 70px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 8px;
      position: relative;
      left: 7px;

      .xe {
        font-weight: bold;
        font-size: 26px;
        color: #354d74;
        position: relative;
        top: 2px;
        margin: 0px 0px;
      }

      .frame {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #354d74;
        font-weight: bold;
        letter-spacing: 0px;
        margin-bottom: 0px;
        line-height: 1;
        font-size: 14px;
        margin: 0px;

        b {
          font-size: 19px;
          display: inline-block;
          margin-left: 3px;
          letter-spacing: 0px;

          margin: 0px 8px;
        }

        .icon {
          font-size: 29px;
          margin: 0px;

          svg {
            position: relative;
            top: 0px;
            left: -2px;
            transform: scale(0.8);
          }

          img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            position: relative;
            top: -4px;

            &.width {
              transform: rotate(180deg);
              margin-left: -1px;
              margin-right: 11px;
            }

            &.height {
              margin-right: -1px;
              margin-left: 5px;
            }
          }
        }

        .icontrol {
          width: 75px;
          margin: 0 auto;
          box-shadow: none;
          padding: 0px 11px;
          box-shadow: none;
          border-radius: 10px;
          background-color: #a9a9a954;
          font-size: 18px;
          font-weight: 600;
          height: 26px;
        }
      }

    }

    .length-frame-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 6px;
    }

    .video-length {
      font-weight: bold;
      color: black;
      font-size: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        color: #354d74;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.red-bold {
          color: #fc4242;
          font-weight: bold;
        }

        &.orange-bold {
          color: #ffa200;
          font-weight: bold;
        }

        b {
          font-size: 18px;
          display: inline-block;
          margin-left: 3px;
          letter-spacing: 0px;
          margin-right: 5px;
        }

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin: 0px 5px;
          position: relative;
          top: -1px;
        }

        svg {
          position: relative;
          top: 0px;
          left: -2px;
          transform: scale(.8);

        }
      }
    }

    .modal-body {
      padding: 30px 20px;
      text-align: -webkit-center;
      text-align: center;
      width: 100%;
    }

    .video-frame-box {
      max-width: 80%;
      margin: 0 auto;
    }

    .playpoint-tool-box {
      position: relative;
    }

    .modal-content .modal-header .btn-close:after {
      content: "X";
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 22px;
      color: white;
      font-weight: 800;
    }
  }

  .fix-frame-box {
    position: relative;

    .fix-frame {
      height: 30px;
      position: absolute;
      border: 1px solid #c7c2c2;
      border-radius: 5px;
      top: -62px;
      display: flex;
      width: 76px;
      background-color: whitesmoke;
      transform: none;

      input {
        font-size: inherit;
        line-height: inherit;
      }

      &.left {
        left: -84px;
      }

      &.right {
        right: -84px;
      }

      .icontrol {
        padding: 0px 0px 0px 7px;
        width: 100%;
        background-color: whitesmoke;
        box-shadow: none;
      }

      div {
        svg {
          stroke: #354d74;
        }
      }
    }
  }

  .modal-dialog.modal-xl {
    width: 100%;
    max-width: 50% !important;
    margin: 0 auto;
    padding: 30px 0px;

    .video-frame img {
      width: 10%;
      max-width: 11%;
      height: 65px;
      position: relative;
      object-fit: cover;

      &:last-child {
        border: none;
      }
    }

    .videoframe-slider-box {
      position: relative;
    }

    .video-frame {
      position: relative;
      width: 100%;
      display: inline-flex;
      max-width: 100%;
      flex-wrap: nowrap;
    }

    .video-slider {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translate(0%, -50%);
      color: #354d74;
    }

    .main-video-playpoint {
      position: absolute;
      width: 4px;
      height: 100%;
      background-color: #f5cd05;
      // left: 0;
      z-index: 999;
      cursor: pointer;
    }

    .play-button {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 45px;
      cursor: pointer;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }

    .video-playpoint {
      position: relative;
    }

    .tool-tip:after {
      content: "";
      position: absolute;
      width: 4px;
      height: 40px;
      background-color: #dea32c;
      left: -1px;
      right: 0;
      top: -47px;
    }

    .tool-tip {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .tool-tip span {
      position: absolute;
      top: -75px;
      left: -27px;
      background-color: #000000b5;
      color: white;
      border-radius: 30px;
      padding: 0px 12px;
      font-size: 14px;
    }

  }

  .input-box {
    position: relative;
  }

  .num-arrows {
    position: absolute;
    right: 0;
  }

  .num-arrows {
    position: absolute;
    right: 5px;
    top: -2px;
    cursor: pointer;
    transform: scale(1.11);
  }

  .fix-frame-box .fix-frame > div {
    position: absolute;
    right: 4px;
    top: -1px;
    cursor: pointer;
  }

  .fix-frame input:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }

  .modal-dialog .modal-title {
    width: 100%;
  }

  .save-btn {
    position: absolute;
    right: 20px;
  }

  .modal-dialog .modal-content {
    height: auto;
  }

  .video-modal.modal {
    overflow: hidden;
    background-color: #0000008c;
  }


  // jignesh sir
  .noUi-target .noUi-connect:before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    min-width: 10000vw;
    width: 10000vw;
    // backdrop-filter: grayscale(1);
    background: #0000005c;
    pointer-events: none;
  }

  .noUi-target .noUi-connect:after {
    content: "" !important;
    position: absolute !important;
    top: 0 !important;
    left: 100% !important;
    bottom: 0 !important;
    min-width: 10000vw !important;
    width: 10000vw !important;
    // -webkit-backdrop-filter: grayscale(1) !important;
    // backdrop-filter: grayscale(1) !important;
    background: #0000005c;
    pointer-events: none;
  }

  // Media Query
  @media screen and (min-width: 1441px) {
    .modal-dialog.modal-xl {
      max-width: 1100px !important;
    }
  }
  @media screen and (max-width: 1440px) {
    .modal-dialog.modal-xl {
      max-width: 70% !important;
    }
  }

  @media screen and (max-width: 1300px) {
    .modal-dialog.modal-xl {
      max-width: 80% !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .modal-dialog.modal-xl {
      max-width: 95% !important;
    }
  }
  @media screen and (max-width: 1023px) {

  }

  .react-player {
    margin: 0 auto;
    height: 360px !important;
  }

  .react-player video {
    /* max-height: 100%; */
    object-fit: contain;
    /* max-width: 100%;
    width: 100%; */
  }

  .Crop-Controls {
    margin-bottom: 10px;
  }

  .Crop-Controls > * {
    margin-bottom: 3px;
  }

  img {
    max-width: 100%;
  }

  .ReactCrop__drag-handle::after {
    border: none !important;
    border-left: 13px solid #f0b354 !important;
    border-top: 13px solid #f0b354 !important;
    background-color: transparent !important;
    width: 35px !important;
    height: 35px !important;
  }

  .ReactCrop .ord-n::after {
    border-left: none !important;
    position: relative;
    left: 0;
    transform: translate(-50%, 0%);
  }

  .ReactCrop .ord-ne::after {
    border-left: none !important;
    border-right: 13px solid #f0b354 !important;
  }

  .ReactCrop .ord-w::after {
    border-top: none !important;
  }

  .ReactCrop .ord-sw::after {
    border-top: none !important;
    border-bottom: 13px solid #f0b354 !important;
  }

  .ReactCrop .ord-se::after {
    border: none !important;
    border-bottom: 13px solid #f0b354 !important;
    border-right: 13px solid #f0b354 !important;
  }

  .ReactCrop .ord-e::after {
    border: none !important;
    border-right: 13px solid #f0b354 !important;
  }

  .ReactCrop .ord-s::after {
    border: none !important;
    border-bottom: 13px solid #f0b354 !important;
    left: 0px;
    transform: translate(-50%, 0%);
  }

  .ReactCrop .ord-n {
    margin-left: 0px !important;
  }

  .ReactCrop .ord-s {
    margin-left: 0px !important;
  }

  .ReactCrop .ord-w::after {
    left: 0;
    top: 0;
    transform: translate(0%, -50%);
  }

  .ReactCrop .ord-e::after {
    right: 0;
    top: 0;
    transform: translate(0%, -50%);
  }

  .ReactCrop__crop-selection {
    /* backdrop-filter: grayscale(1); */
    box-shadow: none !important;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 669px !important;
      margin: 0 auto !important;
    }
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .imagecrop img {
    width: 85px;
    height: 100%;
    margin: 3px;
  }

  .imagecrop {
    background-color: #354d74;
  }

  .irs--round .irs-bar {
    background-color: #00c2c0;
  }

  .irs--round .irs-handle {
    background-color: #00c2c0;
    border-color: #00c2c0;
    box-shadow: 0px 0px 0px 5px rgba(0, 194, 192, 0.2);
  }

  .irs--round .irs-handle.state_hover,
  .irs--round .irs-handle:hover {
    background-color: #00c2c0;
  }

  .irs--round .irs-handle {
    width: 16px;
    height: 16px;
    top: 29px;
  }

  .irs--round .irs-from,
  .irs--round .irs-to,
  .irs--round .irs-single {
    background-color: transparent;
    color: #666666;
  }

  .irs--round .irs-from:before,
  .irs--round .irs-to:before,
  .irs--round .irs-single:before,
  .irs--round .irs-min,
  .irs--round .irs-max {
    display: none;
  }

  // body {
  //   padding: 4em;
  // }
  input[type="range"] {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 400px;
    margin: 0;
    padding: 0 2px;

    overflow: hidden;
    border: 0;
    border-radius: 1px;
    outline: none;
    background: linear-gradient(grey, grey) no-repeat center;

    background-size: 100% 2px;
    pointer-events: none;
  }

  input[type="range"]:active,
  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 28px;
    width: 28px;
    border-radius: 28px;
    background-color: #fff;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
  }

  input[type="range"]::-webkit-slider-thumb::before {
    content: " ";
    display: block;
    position: absolute;
    top: 13px;
    left: 100%;
    width: 2000px;
    height: 2px;
  }

  .multi-range {
    position: relative;
    height: 50px;
  }

  .multi-range input[type="range"] {
    position: absolute;
  }

  .multi-range input[type="range"]:nth-child(1)::-webkit-slider-thumb::before {
    background-color: #354d74;
  }

  .multi-range input[type="range"]:nth-child(2) {
    background: none;
  }

  .multi-range input[type="range"]:nth-child(2)::-webkit-slider-thumb::before {
    background-color: grey;
  }

  /* video css */

  /* Styling Nemi */

  /* hitesh */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }


  // editor.css
  body {
    transition: background-color .3s ease, color .3s ease;
  }

  .file-drop {
    width: 100%;
    height: fit-content;
    font-weight: bold;
    font-size: large;
  }

  .file-drop > .file-drop-target {
    transition: border-color .3s ease;
    // border: 0.2em solid #f04e26;
    border-radius: 1em;
    text-align: center;
    padding: 8em 0em;
    cursor: pointer;
  }

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    border-color: #90ee90;
    transition: border-color .3s ease;
    color: #cacaca
  }

  .hidden {
    display: none;
  }

  .toolBar {
    padding: 1em;
    margin: 2em;
    text-align: center;
    border-radius: 1em;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  > .toggle {
    background-color: rgb(185, 185, 185);
    color: yellow;
    height: 2em;
    width: 2em;
    text-align: center;
    line-height: 2em;
    vertical-align: middle;
    border-radius: 100em;
    font-size: x-large;
    z-index: 100;
    position: fixed;
    bottom: 1em;
    right: 1em;
    cursor: pointer;
    transition: background-color .2s ease;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    > .toggle:hover {
      transition: background-color .2s ease;
      background-color: rgb(146, 146, 146);
    }

  }


  /* Styling for Editing */
  .wrapper {
    width: 60%;
    max-width: 90%;
    padding: 1rem;
    border-radius: 4px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  }

  .video {
    width: 100%;
    cursor: pointer;
    border-radius: 1em;
  }

  .playback {
    position: relative;
    margin-top: 1rem;
    margin-left: 24px;
    margin-right: 24px;
    height: 10px;
    background: #2f3b44;
    margin-bottom: 1rem;
    border-radius: 1em;
  }

  .playback .seekable {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(240, 240, 240);
    cursor: pointer;
  }

  .playback .grabber {
    position: absolute;
    top: -4px;
    bottom: -4px;
    width: 18px;
    border-radius: 2px;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .playback .grabber.start {
    background: #a8d736;
  }

  .playback .grabber.end {
    background: #fc4242;
  }

  .playback .grabber:hover {
    transform: scaleY(1.4);
  }

  .playback .grabber svg {
    /* user-drag: none;
    -moz-user-select: none;
    -webkit-user-drag: none; */
  }

  .playback .progress {
    background: #0072cf;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    pointer-events: none;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  .controls .player-controls button {
    width: 34px;
    margin: 0 0.125rem;
  }

  .controls .player-controls .play-control {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .controls .player-controls .play-control:hover {
    background: #445562;
  }

  .controls .player-controls .play-control:active {
    color: #ffffff;
  }

  .controls .player-controls .seek-start {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .controls .player-controls .seek-start:hover {
    background: #445562;
  }

  .controls .player-controls .seek-start:active {
    color: #ffffff;
  }

  .controls .player-controls .seek-end {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .controls .player-controls .seek-end:hover {
    background: #445562;
  }

  .controls .player-controls .seek-end:active {
    color: #ffffff;
  }

  .controls .settings-control {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .controls .settings-control:active {
    color: #ffffff;
  }

  .controls .settings-control:hover {
    background: #445562;
  }

  .controls .trim-control {
    background: #0072cf;
    border: 0;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .controls .trim-control:hover {
    background: #038eff;
  }

  .margined {
    margin: 0em 0.3em;
  }

  .warning {
    padding: 0.8em;
    color: #da7f00;
    background-color: #ffecd1;
    border-radius: 0.3em;
    border: 0.1em solid #f0ad4e;
    margin: 1em;
    justify-content: center;
    text-align: center;
  }

  .thumbnail {
    width: 20%;
    height: auto;
    justify-content: center;
    align-items: center;
    border: 0.2em solid black;
    border-radius: 0.3em;
  }

  .marginVertical {
    margin: 1.5em 0em;
  }

  .trim-btn .action-btn {
    width: auto !important;
    padding: 0px 80px;
  }

  .modal-content {
    background-clip: padding-box;
    background-color: #fff;
    /* border: var(--bs-modal-border-width) solid var(--bs-modal-border-color); */
    /* border-radius: var(--bs-modal-border-radius); */
    /* color: var(--bs-modal-color); */
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
  }

  .modal-header {
    align-items: center;
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 16px;

    .h4 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .btn-close {
      /* background: transparent url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E) 50%/1em auto no-repeat; */
      border: 0;
      border-radius: 0.375rem;
      box-sizing: initial;
      color: #000;
      height: 1em;
      opacity: .5;
      padding: 8px;
      width: 1em;
    }
  }

  .form-control {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
  }

  .red-bold {
    color: #fc4242;
    font-weight: bold;
  }
  .orange-bold {
    color: #ff8c00;
    font-weight: bold;
  }
}
