.deepmotion-body{


section.range-slider {
  position: relative;
  width: 100%;
  float: left;
  text-align: center;
}
section.range-slider input[type="range"] {
  pointer-events: none;
  position: absolute;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  background: #f1efef;
  box-shadow: inset 0 1px 0 0 #cdc6c6, inset 0 -1px 0 0 #d9d4d4;

  overflow: hidden;
  left: 0;
  top: 50px;
  width: 100%;
  outline: none;
  height: 20px;
  margin: 0;
  padding: 0;
}
section.range-slider input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0;
  -webkit-appearance: none;
  width: 15px;
  height: 60px;
  border: none;
  border-radius: 0px;
  /* background-color: #562edb; */
  background-color: #354d74;
}

section.range-slider input {
  width: 100%;
  max-width: 100%;
}
.video-slider {
  position: absolute;
  /* top: -26px; */
  left: 0;
  right: 0;
}
section.range-slider input[type="range"] {
  top: 27px;
  width: 100%;
  outline: none;
  height: 63px;
  background-color: transparent;
}
.start {
  left: -26px;
}
.end {
  right: -26px;
}

.croparea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.crop-video-btn {
  margin-top: 20px;
}

.noUi-horizontal {
  height: 66px !important;
}

.noUi-horizontal .noUi-handle {
  width: 25px !important;
  height: 67px !important;
  background-color: #354d74;
  background: #354d74;
  box-shadow: 0px 0px 4px 1px #ffffff0d;
  cursor: pointer;
}

.noUi-target {
  background: #fafafa00 !important;
}

.noUi-connect {
  background: #3fb8af29 !important;
}
/* .noUi-horizontal .noUi-handle:before,
.noUi-horizontal .noUi-handle:after {
  display: none;
} */
/* .noUi-horizontal .noUi-handle.noUi-handle-lower {
  content: url("/public/left-chevron.png");
}
.noUi-horizontal .noUi-handle.noUi-handle-upper {
  content: url("/public/next.png");
} */
.noUi-handle {
  position: relative;
  display: inline-block;
  display: block;
}
.noUi-horizontal .noUi-handle.noUi-handle-lower:after {
  content: url("../assets/images/left.svg");
  position: absolute;
  top: 16px;
  left: -14%;
  display: block;
  transform: scale(0.6);
  display: block;
}
.noUi-horizontal .noUi-handle.noUi-handle-upper::after {
  content: url("../assets/images/right.svg");
  position: absolute;
  top: 16px;
  left: -40%;
  display: block;
  transform: scale(0.6);
  padding-left: 10px;
}
.noUi-horizontal .noUi-handle.noUi-handle-lower,
.noUi-horizontal .noUi-handle.noUi-handle-upper {
  position: relative;
}
.noUi-horizontal .noUi-handle:before {
  display: none;
}
.noUi-horizontal .noUi-connect {
  /* border: 5px solid #354d74 !important; */
  background: #ffffff00 !important;
  /* overflow: hidden; */
  /* backdrop-filter: grayscale(1); */
}
.noUi-horizontal .noUi-handle {
  top: 0 !important;
  border: none;
}
.noUi-horizontal .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
  border: 1px solid black;
  border-radius: 30px;
  background-color: #000000c4;
  color: white;
  font-size: 12px;
  padding: 3px 8px;
}
.noUi-horizontal .noUi-handle.noUi-handle-lower {
  position: absolute;
  right: 0;
}
.noUi-horizontal .noUi-handle.noUi-handle-upper {
  position: absolute;
  right: -25px;
}
.noUi-target.noUi-horizontal {
  border: none;
  box-shadow: none;
  position: relative;
}
.noUi-horizontal .noUi-tooltip {
  margin-bottom: 4px;
}
.noUi-handle:before,
.noUi-handle:after {
  background: none !important;
}

/* video::-webkit-media-controls {
  -webkit-backdrop-filter: grayscale(100%);
  backdrop-filter: grayscale(100%);
} */

.ReactCrop__crop-selection:before {
  content: "";
  position: absolute;
  left: 100%;
  top: -500px;
  bottom: -500px;
  backdrop-filter: grayscale(1);
  width: 600px;
}

.ReactCrop__crop-selection:after {
  content: "";
  position: absolute;
  right: 100%;
  top: -500px;
  bottom: -500px;
  backdrop-filter: grayscale(1);
  width: 600px;
}

.ReactCrop__drag-elements:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 100%;
  right: 0;
  backdrop-filter: grayscale(1);
  height: 600px;
}

.ReactCrop__drag-elements:after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  backdrop-filter: grayscale(1);
  height: 600px;
}

.ReactCrop__drag-elements > div {
  z-index: 1;
}
/* video-frame */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

/* 18 Oct  */
.noUi-connect input[type="range"]::-webkit-slider-thumb {
  height: 65px;
  width: 3px;
  border-radius: 28px;
  background-color: #f0b354;
  position: relative;
  margin: 0px 0;
  cursor: pointer;
  appearance: none;
  pointer-events: all;
  box-shadow: 0 1px 4px 0.5px rgb(0 0 0 / 25%);
}

/* range.css */

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
  position: absolute;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #9fe5e1;
  z-index: 2;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
.thumb--zindex-5 {
  z-index: 5;
}
.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
  position: absolute;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}
.noUi-base,
.noUi-connects {
  border: 1px solid #354d74;
}
}