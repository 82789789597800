.LibraryTypeSelection {
  background-color: #eff1fa;
  .DayPicker {
    position: absolute;
    z-index: 99;
    background-color: #ffffff;
    border-radius: 5px;
    bottom: 60px;
  }
  .LibrarySearch {
    &.day {
      position: relative;
      width: 320px;
      .search {
        .input {
          padding-left: calc(0.75em - 1px);
        }
      }
    }
    .search {
      position: relative;
      .searchBtn {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .clearBtn {
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        &.show {
          display: block;
        }
      }
      .input {
        padding-left: 45px;
        height: 50px;
        line-height: 50px;
        border-radius: 10px;
        background-color: #eaf1fb;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
    svg {
      position: absolute;
      fill: #444746;
      width: 30px;
      padding: 2px;
      z-index: 9;
      border-radius: 50%;
      &:hover {
        background-color: rgba(60, 64, 67, 0.08);
      }
    }
  }
}
