.MultiPersonSelectorSwitch {
  position: relative;
  overflow: hidden;
  .flex-1 {
    flex: 1;
  }
  .float-right {
    float: right;
  }
  &.is-lock {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      left: 0;
      top: 0;
    }
  }

  svg {
    fill: #ffffff;
    height: 60px;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
  }

  .dm-brand-2-font {
    svg {
      fill: var(--dm-brand-alt);
    }
  }
}
