.backdrop {
  max-width: 600px;
  &.fullWidth {
    max-width: 600px;
  }
  .backdropSwiper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .swiper-navigation {
      position: absolute;
      z-index: 5;
      visibility: visible !important;
      height: auto !important;
      cursor: pointer;
      color: var(--dm-brand-alt);
      text-shadow: 0px 2px 8px #898989;
      transition: all 0.3s;
      &.notEnabled {
        color: #cccccc;
      }
      &.backdrop-swiper-prev {
        left: 0;
      }
      &.backdrop-swiper-next {
        right: 0;
      }
    }
  }
  .Backdrop-box .img {
    vertical-align: bottom;
  }
  .Backdrop-box.default {
    background-image: url("../images/backdrop/default.jpg");
    background-size: 100% 100%;
  }
  .Backdrop-box.defaultWithOriginal {
    background-image: url("../images/backdrop/defaultWithOriginal.jpg");
    background-size: 100% 100%;
  }
  .Backdrop-box.environments {
    background-image: url("../images/backdrop/default.jpg");
    background-size: 100% 100%;
  }
  .Backdrop-box.transparent {
    background-color: white;
    background-image:
            linear-gradient(45deg, rgba(0, 0, 0, 0.2) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 75%),
            linear-gradient(45deg, rgba(0, 0, 0, 0.2) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 75%);
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
  }
  .Backdrop-box.solid {
    background-color: none;
  }
  .Backdrop-box.studio {
    position: relative;
    display: block;
  }
  .Backdrop-box.studio .img {
    position: relative;
    z-index: 1;
  }
  .Backdrop-box.studio .after {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      180deg,
      rgb(0, 80, 0) 10%,
      rgb(0, 128, 0) 50%,
      rgb(0, 80, 0) 90%
    );
  }
  .Backdrop-box.studio .before {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 50%;
    left: 0;
    background-image: radial-gradient(
      closest-side at 50% 50%,
      rgb(0, 128, 0),
      rgb(0, 80, 0)
    );
  }
}
