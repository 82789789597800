:root {
  --dark: #000;
  --light: #fff;
  --dark-primary: #141414;
  --light-primary: #f5f5f7;

  --dm-brand: #2d4e77;
  --dm-brand-alt: #fab03c;

  --dark-title: #f5f5f7;
  --light-title: #141414;
  --background-color: white;

  --slider-width: 80px;
  --slider-thumb-width: 20px;
}

* {
  margin: auto;
  font-family: Helvetica, Arial, sans-serif;
}

.dm-brand {
  background-color: var(--dm-brand) !important;
}

.dm-brand-2 {
  background-color: var(--dm-brand-alt) !important;
}

.dm-brand-2-hover:hover {
  background-color: var(--dm-brand-alt) !important;
}

.dm-brand-font {
  color: var(--dm-brand) !important;
}

.dm-brand-font-hover:hover {
  color: var(--dm-brand) !important;
}

.dm-brand-2-font {
  color: var(--dm-brand-alt) !important;
}

.dm-brand-2-font-hover:hover {
  color: var(--dm-brand-alt) !important;
}

.dm-progress-bar-freemium::-moz-progress-bar {
  background-color: rgb(250, 176, 60) !important;
}

.dm-progress-bar-freemium::-webkit-progress-value {
  background-color: rgb(250, 176, 60) !important;
}

.dm-progress-bar-starter::-moz-progress-bar {
  background-color: rgb(72, 199, 142) !important;
}

.dm-progress-bar-starter::-webkit-progress-value {
  background-color: rgb(72, 199, 142) !important;
}

.dm-progress-bar-innovator::-moz-progress-bar {
  background-color: rgb(0, 209, 178) !important;
}

.dm-progress-bar-innovator::-webkit-progress-value {
  background-color: rgb(0, 209, 178) !important;
}

.dm-progress-bar-professional::-moz-progress-bar {
  background-color: rgb(32, 156, 238) !important;
}

.dm-progress-bar-professional::-webkit-progress-value {
  background-color: rgb(32, 156, 238) !important;
}

.dm-progress-bar-studio::-moz-progress-bar {
  background-color: rgb(72, 95, 199) !important;
}

.dm-progress-bar-studio::-webkit-progress-value {
  background-color: rgb(72, 95, 199) !important;
}

.bg-ab {
  background: rgba(45, 78, 119, 0.65);
}

.text-3d-shadow {
  color: #2d4e77 !important;
  text-shadow: 2px 2px lightblue !important;
  z-index: 5 !important;
}

.text-3d-shadow-alt {
  color: #fab03c !important;
  text-shadow: 2px 2px #2d4e77 !important;
  z-index: 5 !important;
}

.has-background-transparent {
  background-color: transparent !important;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3s linear;
  }
}

.logo-text-blink {
  animation: blinker 1.4s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  to {
    opacity: 0;
  }
}

/*.App-logo-text {
    animation: logo-text-blink infinite 2s linear;
  }*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*@keyframes logo-text-blink {
  50% {
    opacity: 0;
  }
}*/

.no-outline-on-active {
}

.no-outline-on-active:active {
  outline: none;
}

.btn-shadow {
  box-shadow: 0 4px 0 0 #444, 0 4px 4px 1px rgb(0 0 0 / 50%) !important;
}

.btn-shadow:focus {
  outline: none;
  box-shadow: none;
}

.btn-3d-effect {
  transition: margin-top 0.3s ease, margin-left 0.3s ease, box-shadow 0.3s ease;
  background: #fab03c;
  border: solid 1px #8a590c;
  box-shadow: 1px 0px 0px #8a590c, 0px 1px 0px #8a590c, 2px 1px 0px #8a590c,
    1px 2px 0px #8a590c, 3px 2px 0px #8a590c, 2px 3px 0px #8a590c,
    4px 3px 0px #8a590c, 3px 4px 0px #8a590c, 5px 4px 0px #8a590c,
    4px 5px 0px #8a590c, 6px 5px 0px #8a590c, 5px 6px 0px #8a590c,
    7px 6px 0px #8a590c, 6px 7px 0px #8a590c, 8px 7px 0px #8a590c,
    7px 8px 0px #8a590c, 9px 8px 0px #8a590c, 8px 9px 0px #8a590c;
}

.btn-3d-effect:active {
  transition: margin-top 0.3s ease, margin-left 0.3s ease, box-shadow 0.3s ease;
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 0px 0px 0px #8a590c, 0px 0px 0px #8a590c, 0px 0px 0px #8a590c,
    0px 0px 0px #8a590c;
}

.btn-brand-alt {
  background-color: var(--dm-brand-alt) !important;
  border: 2px solid var(--dm-brand-alt);
  color: var(--dm-brand);
}

.btn-brand-alt-outline {
  background-color: transparent !important;
  border: 2px solid var(--dm-brand-alt);
  color: var(--dm-brand-alt);
}

.btn-brand-alt-outline:focus {
  background-color: var(--dm-brand-alt) !important;
  border: 2px solid var(--dm-brand-alt) !important;
  color: var(--dm-brand) !important;
}

.btn-brand-alt-outline:hover {
  background-color: var(--dm-brand-alt) !important;
  border: 2px solid var(--dm-brand-alt) !important;
  color: var(--dm-brand) !important;
}

/*.steps-segment:after {
  background-color: #00d1b2 !important;
}*/
.steps-segment.is-active ~ .steps-segment::after {
  background-color: #dbdbdb;
}

.img-16by9-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.img-16by9-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-card-big {
  width: 1000px;
}
.modal-card-head {
  background: #2d4e77 !important;
}

.modal-card-title {
  color: white !important;
}

.modal-card-head-alt {
  background: whitesmoke !important;
}

.modal-card-title-alt {
  color: black !important;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-y {
  overflow-y: scroll;
}

.cursor-grab {
  cursor: grab !important;
}

.no-cursor {
  cursor: not-allowed !important;
}

.no-border {
  border: none !important;
}

.max-col-height {
  max-height: 56px !important;
}

.icon-max-w {
  max-height: 200px !important;
}

.img-outline {
  border-radius: 8px;
  border: 2px solid black;
}

.download-modal-bkgd {
  background-color: #4671ad !important;
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked + .toggle-switch {
  background: #56c080;
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  margin-right: 0.25rem;
  position: relative;
  top: 2px;
}

.disabled-switch {
  background: rgba(0, 0, 0, 0.2) !important;
}

.disabled-switch::before {
  background: transparent !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) !important;
}

.disabled-switch-value-on {
  background: #56c080 !important;
  box-shadow: 0 0 0 1px #56c080 !important;
}

.tip-max-w {
  max-width: 550px !important;
}

.tip-max-w-narrow {
  max-width: 250px !important;
}

.disabled-item {
  background: rgba(0, 0, 0, 0.2) !important;
  border-color: transparent !important;
}

.disabled-item:after {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.disabled-text {
  color: rgba(0, 0, 0, 0.2) !important;
}

.panel-block:last-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* Ready Player Me (RMP) properties: */
.rpm-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.rpm-content {
  width: 100%;
  border: 1px solid #2d4e77;
}

.rpm-save {
  font-family: Roboto;
  margin-bottom: 10px;
  background: #cccccc;
  border: none;
  border-radius: 20px;
  color: #f5f5f5;
  cursor: no-drop;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  left: calc(60% - 122px);
  transition: background 0.3s;
}

.rpm-save.have-data {
  background: #ff603e;
  cursor: pointer;
}

.rpm-container-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: #ffffff;
  font-size: 28px;
  background: #000000;
}

.rpm-container-loading span {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 8px;
  animation: waveAnimation 1s ease-in-out infinite;
}

@keyframes waveAnimation {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-20px);
  }

  40%,
  100% {
    transform: translateY(0);
  }
}

.avatars {
  max-width: 300px;
  box-shadow: 10px 10px 10px #cbcbcb;
}

.avatars-img {
  border-radius: 4px;
}

.dm-white {
  color: white;
}

.dm-gray {
  color: gray;
}

.dm-brand-grad {
  background-image: -webkit-linear-gradient(left top, #2d4e77, #1f618d);
}

.loading-bar {
  background-image: linear-gradient(
    to right,
    #2d4e77 30%,
    #ededed 30%
  ) !important;
}

.loading-bar-alt {
  background-image: linear-gradient(
    to right,
    #fab03c 30%,
    white 30%
  ) !important;
}

.dm-brand-border {
  border: 1px solid #2d4e77 !important;
}

.dm-brand-border-md {
  border: 2px solid #2d4e77 !important;
}

.dm-brand-border-md-alt {
  border: 2px solid #fab03c !important;
}

.dm-brand-border-lg {
  border: 2px solid #2d4e77 !important;
}

.dm-brand-border-alt-lg {
  border: 3px solid #fab03c !important;
}

.dm-brand-border-alt-lg-6 {
  border: 6px solid #fab03c !important;
}

.border-disabled {
  border: 10px solid #2d4e77 !important;
}

.tile-new-anim {
  background-color: transparent !important;
  color: #fab03c !important;
  border: 2px solid #fab03c !important;
}

.tile-new-anim:hover,
.tile-new-anim:focus {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  border: 2px solid #fab03c !important;
}

.action-panel {
  min-height: 125px !important;
}

.panel-block {
  background-color: lightgray;
}

.hide {
  opacity: 0 !important;
}

.show {
  opacity: 1 !important;
}

.noHover {
  pointer-events: none !important;
}

.disp-grid {
  display: grid;
}

.overlay {
  grid-area: 1 / 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.success-icon {
  color: green !important;
}

.fail-icon {
  color: red !important;
}

.checkbox-opt {
  margin-left: 7px !important;
}

.thumbnail-unavail {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  width: 90%;
}

.thumbnail-unavail-content {
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 4px;
}

.model-thumbnails-list {
  width: 33.33%;
}
.model-thumbnails-list .AccountCharacters {
  width: 100%;
}

.mh-200 {
  max-height: 200px;
}

.profile-btn {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  outline: none !important;
}

.profile-btn:focus {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  outline: none !important;
}

.profile-btn:hover {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  outline: none !important;
}

.guide-btn-disabled,
.guide-btn-disabled:hover,
.guide-btn-disabled:focus {
  color: gray !important;
  background-color: rgba(45, 78, 119, 0.25) !important;
  border: 1px solid rgba(45, 78, 119, 0.25) !important;
}

.action-btn-disabled {
  background-color: transparent !important;
  border: 1px #fab03c !important;
  color: #fab03c !important;
}

.create-anim-btn-disabled {
  background-color: transparent !important;
  border: 2px solid #fab03c !important;
  color: #fab03c !important;
}

.side-menu {
  min-height: 100vh !important;
  background: linear-gradient(
    90deg,
    rgba(240, 230, 230, 1) 0%,
    rgba(247, 243, 243, 1) 100%
  ) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  padding: 3rem 2rem 3rem 2rem !important;
  border-top: 2px solid white !important;
}

.side-menu-collapsed {
  background: linear-gradient(
    90deg,
    rgba(240, 230, 230, 1) 0%,
    rgba(247, 243, 243, 1) 100%
  ) !important;
  width: 35px !important;
  height: 100vh !important;
}

.side-menu-icon {
  position: fixed !important;
  top: 50% !important;
  z-index: 1 !important;
}

.sm-icon-color {
  color: lightgray !important;
}

.js-help-link {
  display: none !important;
}

.collapse-icon-wrapper {
  position: absolute !important;
  top: 0 !important;
  right: 15px !important;
  bottom: 0 !important;
}

.columns.is-active {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.green-screen-box {
  height: 42px !important;
  width: 5rem !important;
  border: 1px solid black;
  border-radius: 6px !important;
  margin-bottom: 0px !important;
}

.mp4-bkgd-box {
  height: 42px !important;
  width: 57px !important;
  border: 1px solid black;
  border-radius: 6px !important;
  margin: 0 1.5rem 0 0 !important;
}

.dropdown-content {
  border-radius: 4px;
  border: 1px solid #2d4e77;
}

.Dropzone {
  height: 100%;
  width: 100%;
  min-height: 200px !important;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.FileInput {
  display: none;
}

.item-enabled {
  color: #2d4e77 !important;
  font-weight: bold !important;
}

.item-enabled:hover {
  box-shadow: 0 4px 0 0 #777, 0 4px 4px 1px rgb(0 0 0 / 50%) !important;
}

.item-disabled {
  color: gray !important;
  font-weight: normal !important;
}

.app-section {
  margin-top: 25px !important;
}

.tabs-area {
  margin: 0 3% 5% 3% !important;
}

.bx-0 {
  border-right: none !important;
  border-left: none !important;
}

.br-0 {
  border-right: none !important;
}

.bl-0 {
  border-left: none !important;
}

.br-4 {
  border-radius: 4px !important;
}

.br-4-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.br-4-bot {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.br-4-top-left {
  border-top-left-radius: 4px !important;
}

.br-4-top-right {
  border-top-right-radius: 4px !important;
}

.br-4-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.br-4-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-8 {
  border-radius: 8px !important;
}

.br-none {
  border-radius: 0 !important;
}

.br-top-none {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.br-bot-none {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.main-new-anim-btn {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  box-shadow: 0 0 5px 5px #2d4e77;
  align-items: center !important;
  box-shadow: #000 1px 1px 0, #000 2px 2px 0, #000 3px 3px 0, #000 4px 4px 0,
    #000 5px 5px 0, #000 6px 6px 0, #000 7px 7px 0, #000 8px 8px 0;
}

.embossed {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.card {
  border: 5px solid #2d4e77;
}

.selection-card {
}

.selection-card:hover {
  /*border: 2px solid #fab03c !important;*/
  box-shadow: 0 0 20px #fab03c !important;
}

.selection-card-active {
  border: 2px solid #fab03c !important;
  box-shadow: 0 0 20px #fab03c !important;
}

.announcement-link {
  text-decoration: none !important;
}

.announcement-link:hover {
  text-decoration: underline !important;
  color: rgb(50, 115, 220) !important;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
}

.flex-vert-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.highlight-bkgd {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

.social-menu {
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  gap: 0.5rem;
  margin-top: 35px;
  margin-bottom: 25px;
}
.social-menu .social-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: var(--dm-brand);
  border-radius: 5px;
  flex-shrink: 0;
}
.menu-list a {
  border-radius: 4px !important;
}

.menu-list a:hover {
  background-color: #2d4e77 !important;
  color: white !important;
}

.menu-label {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(45, 78, 119, 0)
  );
  border-image-slice: 1;
}

.menu-top-section {
  height: 100px !important;
}

.dash-title {
  background: linear-gradient(
    90deg,
    rgba(210, 227, 243, 1) 0%,
    rgba(45, 78, 119, 1) 25%,
    rgba(45, 78, 119, 1) 75%,
    rgba(210, 227, 243, 1) 100%
  );
  border-radius: 4px;
}

.rest-api-bkgd {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(45, 78, 119, 1) 25%,
    rgba(45, 78, 119, 1) 75%,
    rgba(0, 0, 0, 1) 100%
  );
}

.prod-lock-bkgd {
  background: linear-gradient(
    309deg,
    rgba(28, 21, 21, 0.85) 0%,
    rgba(41, 37, 37, 0.85) 50%,
    rgba(28, 21, 21, 0.85) 100%
  );
}

.prod-lock-pos {
  position: absolute;
  top: 35%;
}

.v-and-h-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.meter-max-w {
  max-width: 150px !important;
}

.progress-time-remain {
  position: relative;
  height: 24px;
  border-radius: 12px;
  background-color: #ededed;
}

.progress-time-new {
  position: absolute;
  height: 24px;
  left: 0;
  top: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fab03c;
}

.progress-time-new-full {
  position: absolute;
  height: 24px;
  left: 0;
  top: 0;
  border-radius: 12px;
  background-color: red;
}

.particles-wrapper {
  width: 100%;
  overflow: hidden;
}

.particles-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 60px;
  z-index: -50 !important;
  min-height: 100%;
  margin: 0;
}

.particles-content {
  width: 100%;
  height: 100%;
  margin: 0;
}

.prod-tile {
  min-height: 225px !important;
  background-color: #2d4e77 !important;
  padding: 0 !important;
  margin: 20px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  border: 4px solid #2d4e77;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  -webkit-background-repeat: no-repeat;
  -moz-background-repeat: cover;
  -o-background-repeat: cover;
  background-repeat: no-repeat;

  -webkit-background-position: center center;
  -moz-background-position: center center;
  -o-background-position: center center;
  background-position: center center;

  display: flex !important;
  align-items: center;
}

.prod-tile::after {
  content: "";
  border-radius: 6px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: border-width 0.5s ease-in-out;
}

/*
.prod-tile:focus {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}*/
.prod-tile:hover::after {
  opacity: 1;
}

.prod-tile-active {
  min-height: 225px !important;
  background-color: #2d4e77 !important;
  padding: 0 !important;
  margin: 20px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  border: 4px solid #2d4e77;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  -webkit-background-repeat: no-repeat;
  -moz-background-repeat: cover;
  -o-background-repeat: cover;
  background-repeat: no-repeat;

  -webkit-background-position: center center;
  -moz-background-position: center center;
  -o-background-position: center center;
  background-position: center center;

  display: flex !important;
  align-items: center;
}

.prod-tile-active::after {
  content: "";
  border-radius: 6px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: border-width 0.5s ease-in-out;
}

.prod-tile-active:hover {
  border-radius: 6px;
  animation: border-pulse 1.5s infinite;
}

.prod-tile-active:focus {
  border-radius: 6px;
  animation: border-pulse 1.5s infinite;
}

.prod-tile-active:hover::after {
  opacity: 1;
}

.tile-border-pulse {
  animation: border-pulse 1.5s infinite;
}

.tile-border-pulse-hover {
}

.tile-border-pulse-hover:hover {
  animation: border-pulse 1.5s infinite;
}

.tile-border-pulse-lg {
  animation: border-pulse-lg 1.5s infinite;
}

.new-anim-grad {
  background: linear-gradient(
    309deg,
    rgba(250, 176, 60, 1) 0%,
    rgba(220, 145, 0, 1) 50%,
    rgba(250, 176, 60, 1) 100%
  );
}

.a3d-home-tile {
  cursor: pointer !important;
  border: 2px solid #2d4e77;
}

.a3d-home-tile::after {
  content: "";
  border-radius: 2px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: border-width 0.5s ease-in-out;
}

.a3d-home-tile:hover::after {
  opacity: 1;
}

.a3d-home-tile:hover {
  border-radius: 2px;
  border: 4px solid #fab03c;
  animation: border-pulse 1.5s infinite;
}

.a3d-home-tile:focus {
  border-radius: 2px;
  border: 4px solid #fab03c;
  animation: border-pulse 1.5s infinite;
}

.a3d-home-tile-disabled {
  cursor: pointer !important;
  border: 2px solid #2d4e77;
}

.a3d-tile-link {
  text-decoration: none !important;
}

@keyframes border-pulse {
  0% {
    border-color: rgba(250, 176, 60, 1);
    scale: (1, 1);
    -webkit-transform: scale(1, 1);
  }

  50% {
    border-color: rgba(250, 176, 60, 0.9);
    scale: (0.955, 0.955);
    -webkit-transform: scale(0.955, 0.955);
  }

  100% {
    border-color: rgba(250, 176, 60, 1);
    scale: (1, 1);
    -webkit-transform: scale(1, 1);
  }
}

@keyframes border-pulse-lg {
  0% {
    border-color: rgba(250, 176, 60, 1);
    scale: (1.1, 1.1);
    -webkit-transform: scale(1, 1);
  }

  50% {
    border-color: rgba(250, 176, 60, 0.9);
    scale: (0.9, 0.9);
    -webkit-transform: scale(0.955, 0.955);
  }

  100% {
    border-color: rgba(250, 176, 60, 1);
    scale: (1.1, 1.1);
    -webkit-transform: scale(1, 1);
  }
}

.heading {
  font-size: 18px !important;
}

table {
  border-collapse: separate;
  border-bottom: 2px solid #2d4e77 !important;
}

td {
  border: 2px solid #2d4e77 !important;
  border-bottom: 0px !important;
}

th {
  border: 2px solid #2d4e77 !important;
  border-bottom: 0px !important;
  cursor: auto;
}
.rdp-months table,
.rdp-months td,
.rdp-months th {
  border: none !important;
  text-align: center !important;
}
.dm-brand-border-r {
  border-right: 0px !important;
}

.th {
  border: 2px solid #2d4e77 !important;
}

.table-border-brand {
  border: 2px solid #2d4e77 !important;
}

.td-half {
  width: 50% !important;
  border-right: 0px !important;
}

.td-quart {
  width: 25% !important;
}

.td-30 {
  width: 30% !important;
}

.td-40 {
  width: 40% !important;
}

.td-no-border {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.library-table-border {
  border: 1px solid #dbdbdb !important;
}

.library-table-header {
  vertical-align: middle !important;
}

.library-table-header-icon {
  display: inline !important;
}

.home-tile-border-r {
  border-right: 2px solid white;
}

.swiper-container-default-models {
  max-width: 400px !important;
  max-height: 500px !important;
}

.swiper-container-custom-models {
  width: 33vw !important;
  max-width: 33vw !important;
}

.swiper-container-FTE {
  max-width: 600px !important;
}

.swiper-wrapper {
  padding-inline-start: 0;
}

.swiper-slide {
  margin-top: 0 !important;
}

.swiper-wrapper > .swiper-slide-active {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.swiper-slide-custom-models:not(.swiper-slide-active) {
  visibility: hidden !important;
}

.swiper-slide-custom-models:not(.swiper-slide-active) {
  visibility: hidden !important;
}

.swiper-next-custom {
  position: absolute;
  top: 50%;
  right: -1rem;
}

.swiper-prev-custom {
  position: absolute;
  top: 50%;
  left: -1rem;
}

.swiper-next-page {
  position: absolute;
  top: 50%;
  right: 0;
}

.swiper-button-disabled {
  visibility: hidden !important;
  height: 0 !important;
}

.pagination .page-item .page-link {
  font-size: 12px;
}

.pagination-link.is-current {
  background-color: #2d4e77 !important;
  border-color: #2d4e77 !important;
  color: #fff !important;
}

.sort-hover span {
  visibility: hidden;
}

.sort-hover:hover span {
  visibility: visible;
}

.fullwidth {
  width: 100% !important;
}

.fullheight {
  height: 100% !important;
}

.library-name-col {
  width: 30% !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

/************************************************************
 * Dashboard Product button icon slide
 ************************************************************/
.dash-prod-btn {
  border-color: white !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  color: white !important;
}

.dash-prod-btn:hover {
  border-color: white !important;
  color: white !important;
}

.tips-col {
  min-height: 350px;
}

.mgTop-40 {
  margin-top: 40px !important;
}

.mgBot-20 {
  margin-bottom: 20px !important;
}

.mgLeft-20 {
  margin-left: 20px !important;
}

.mgRight-5 {
  margin-right: 5px !important;
}

.mgRight-10 {
  margin-right: 10px !important;
}

.mgRight-20 {
  margin-right: 20px !important;
}

.mgRight-20 {
  margin-right: 20px !important;
}

.mgRight-50 {
  margin-right: 50px !important;
}

.mgTop-20 {
  margin-top: 20px !important;
}

.mgTop-50 {
  margin-top: 50px !important;
}

.mgLeft-5 {
  margin-left: 5px !important;
}

.no-side-margins {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.auto-margins {
  margin-left: auto !important;
  margin-right: auto !important;
}

.profile-margins {
  margin-left: 5% !important;
  margin-right: 5% !important;
  padding: 1.5rem 1.5rem !important;
}

.profile-label {
  border-bottom: 2px solid lightgray;
  border-image: linear-gradient(
    to right,
    rgba(45, 78, 119, 1),
    rgba(45, 78, 119, 0)
  );
  border-image-slice: 1;
}

.cursor-pt {
  cursor: pointer;
}

.cursor-not-allow {
  cursor: not-allowed;
}

.select-tile-left {
  margin-left: auto !important;
  margin-right: 20px !important;
  border-radius: 8px !important;
  min-height: 150px !important;
}

.select-tile-right {
  margin-left: 20px !important;
  margin-right: auto !important;
  border-radius: 8px !important;
  min-height: 150px !important;
}

.navbar {
  background-color: #2d4e77;
  padding: 0 1%;
}

.navbar-end {
  margin-right: 10px;
}

.navbar-item {
  background-color: transparent !important;
}

.navbar-link:focus-within {
  background-color: transparent !important;
}

.navbar-item:focus-within {
  background-color: transparent !important;
}

.navbar-item:focus {
  background-color: transparent !important;
}

.navbar-link:focus {
  background-color: transparent !important;
}

.app-version {
  width: 150px;
  color: #2d4e77;
  padding-left: 2%;
  padding-right: 2%;
}

.sales-btn {
  border-color: #fab03c !important;
  background-color: transparent !important;
  color: #fab03c !important;
}

.sales-btn:focus {
  border-color: #2d4e77 !important;
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.sales-btn:hover {
  border-color: #2d4e77 !important;
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.action-btn {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  border-color: #fab03c !important;
}

.action-btn:focus {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  border-color: #fab03c !important;
}

.action-btn-dark {
  background-color: #2d4e77 !important;
  color: white !important;
  border-color: #2d4e77 !important;
}

.action-btn-dark:focus {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  border-color: #fab03c !important;
}

.action-btn-dark:hover {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  border-color: #fab03c !important;
}

.header-btn {
  z-index: 4 !important;
  background-color: transparent !important;
  color: white !important;
  border-color: #2d4e77 !important;
  font-family: Helvetica, Arial, sans-serif !important;
}

.header-btn:hover {
  z-index: 4 !important;
  background-color: transparent !important;
  border-color: white !important;
  color: white !important;
}

.header-btn:focus {
  z-index: 4 !important;
  background-color: transparent !important;
  border-color: white !important;
  color: white !important;
}

.discard-btn {
  background-color: transparent !important;
  border-color: #2d4e77 !important;
  color: #2d4e77 !important;
}

.discard-btn-no-outline {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #2d4e77 !important;
}

.btn-no-outline {
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-no-outline:hover,
.btn-no-outline:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: underline !important;
}

.remove-btn {
  background-color: transparent !important;
  border-color: white !important;
  color: white !important;
}

.remove-btn:hover {
  background-color: #2d4e77 !important;
  border-color: white !important;
  color: white !important;
}

.cancel-btn {
  border-color: #2d4e77 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: #2d4e77 !important;
}

.cancel-btn:focus {
  border-color: #fab03c !important;
  background-color: white !important;
  color: #fab03c !important;
}

.cancel-btn:hover {
  border-color: white !important;
  background-color: #2d4e77 !important;
  color: white !important;
}

.logout-btn {
  color: white !important;
  background-color: transparent !important;
}

.logout-btn:focus {
  color: #2d4e77 !important;
  background-color: transparent !important;
}

.logout-btn:hover {
  color: #2d4e77 !important;
  background-color: transparent !important;
}

.back-btn {
  border-color: #dbdbdb;
  color: #dbdbdb;
  background-color: white;
  min-width: 2.5em;
  text-decoration: none;
}

.cancel-job-btn {
  border-color: white !important;
  background-color: transparent !important;
  color: white !important;
}

.cancel-job-btn:focus {
  border-color: white !important;
  background-color: transparent !important;
  color: white !important;
}

.cancel-job-btn:hover {
  border-color: #fab03c !important;
  background-color: transparent !important;
  color: #fab03c !important;
}

.nav-action {
  background-color: #2d4e77 !important;
  color: #fab03c !important;
  border-color: #fab03c !important;
}

.nav-action:focus {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  border-color: #fab03c !important;
}

.nav-action:hover {
  background-color: #fab03c !important;
  color: #2d4e77 !important;
  border-color: #fab03c !important;
}

.dropdown-item:hover {
  outline: none;
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.dropdown-item-disabled:hover,
.dropdown-item-disabled:active,
.dropdown-item-disabled:focus {
  border: none !important;
  outline: none !important;
  background-color: #f7f3f3 !important;
  color: #2d4e77 !important;
}

.dropdown-item:active,
.dropdown-item:focus {
  border: 1px solid #2d4e77;
  border-radius: 4px;
}

.dropdown-item-alt:hover {
  outline: none !important;
  color: #2d4e77 !important;
}

.dropdown-item-alt:active,
.dropdown-item:focus {
  border: none !important;
  border-radius: 4px;
}

.dropdown-knob-bkgd {
  background-color: rgba(50, 50, 50, 1) !important;
  width: 120px;
  height: 120px;
  padding: 0;
  margin-left: 0;
  border: 2px solid #2d4e77;
  display: flex;
  vertical-align: middle;
}

.td-col-center {
  font-size: 16px;
  color: #2d4e77;
  text-align: center !important;
  vertical-align: middle !important;
}

/* white drop down arrow for navbar header */
.navbar-link:not(.is-arrowless)::after {
  border-color: white;
  background-color: transparent;
}

.bShadow {
  -webkit-box-shadow: 0px 44px 23px -24px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 44px 23px -24px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0px 44px 23px -24px rgba(0, 0, 0, 0.75) !important;
}

.summary-box {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.info-area {
  border-radius: 12px;
  margin-top: 40px;
  margin-bottom: 25px;
  padding: 10px 25px;
}

.preview-btn {
  width: 100%;
  background-color: #2d4e77 !important;
  color: white !important;
}

.preview-btn:focus {
  width: 100%;
  transition: 0.35s;
  left: 0;
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.preview-btn:hover {
  width: 100%;
  transition: 0.35s;
  left: 0;
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.library-btn {
  background-color: #2d4e77 !important;
  color: white !important;
}

.library-btn:focus {
  transition: 0.35s;
  left: 0;
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.library-btn:hover {
  transition: 0.35s;
  left: 0;
  background-color: #fab03c !important;
  color: #2d4e77 !important;
}

.download-btn {
  border-color: #2d4e77 !important;
  background-color: #2d4e77 !important;
  color: white !important;
}

.download-btn:focus {
  border-color: #2d4e77 !important;
  background-color: #2d4e77 !important;
  color: white !important;
}

.download-btn:hover {
  transition: 0.35s;
  left: 0;
  background-color: #fab03c !important;
  border-color: #fab03c !important;
  color: #2d4e77 !important;
}

.bottom-border {
  border-bottom: solid 2px white;
}

.bottom-border-brand {
  border-bottom: solid 2px var(--dm-brand);
}

.bottom-border-brand-alt {
  border-bottom: solid 2px var(--dm-brand-alt);
}

.top-border {
  border-top: solid 2px white;
}

.left-border {
  border-left: solid 2px white;
}

.right-border {
  border-right: solid 2px white;
}

.top-border-brand-sm {
  border-top: solid 1px var(--dm-brand);
}

.rounded-corners {
  border-radius: 12px;
}

.rounded-corners-top {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.rounded-corners-bottom {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.modal-card-header,
.modal-card-body,
.modal-card-footer {
  margin: 0;
}

#okta-sign-in.auth-container .button-primary {
  background: #2d4e77 !important;
  box-shadow: 0 4px 0 0 #777, 0 4px 4px 1px rgb(0 0 0 / 50%) !important;
}

.button-primary:focus {
  outline: none;
  box-shadow: none;
}

.page-item {
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.page-link {
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.button {
  background-color: #2d4e77;
  color: white;
}

.button:focus {
  outline: none;
  box-shadow: none;
}

.button-side-menu {
  background-color: transparent;
  color: #2d4e77;
  outline: none;
  border: none;
  box-shadow: none;
}
.button-side-menu svg path {
  fill: #2d4e77;
}

.button-side-menu:hover {
  background-color: #2d4e77 !important;
  color: white !important;
  box-shadow: 0 4px 0 0 #444, 0 4px 4px 1px rgb(0 0 0 / 50%) !important;
}
.button-side-menu:hover svg path {
  fill: white;
}

.row {
  padding: 0px 50px;
  margin: 0;
}

hr {
  border-top: 1px solid rgb(214, 213, 213);
}

#sessionId,
#uid {
  display: none;
}

.logo {
  border-bottom: none;
  width: 280px;
  height: 60px;
}

th,
td {
  font-size: 1.5rem;
}

@media only screen and (max-width: 1024px) {
  .logo {
    height: 35px;
  }

  .header span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 30px;
  }

  .sublogo {
    height: fit-content;
  }

  .header span {
    font-size: 12px;
    position: absolute;
  }

  .left {
    border-right: none;
  }

  hr {
    display: none;
  }

  .right h1,
  .right a {
    padding: 0;
  }
}

/*************************
 Primary progress meter
 *************************/
.meter {
  height: 50px;
  margin-left: 0;
  position: relative;
  background: #e0e0eb;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  padding: 0px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter > span {
  display: block;
  height: 100%;
  margin-left: 0;
  -webkit-border-top-right-radius: 12px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-topright: 12px;
  -moz-border-radius-bottomright: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  -webkit-border-top-left-radius: 12px;
  -webkit-border-bottom-left-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: rgb(250, 176, 60);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgba(250, 176, 60, 0.5)),
    color-stop(1, rgb(250, 176, 60))
  );
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(25, 40, 118) 37%,
    rgb(45, 66, 119) 69%
  );
  -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}

.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -webkit-gradient(
    linear,
    0 0,
    100% 100%,
    color-stop(0.25, rgba(255, 255, 255, 0.2)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  -webkit-background-size: 50px 50px;
  -moz-background-size: 50px 50px;
  -webkit-animation: move 2s linear infinite;
  animation: move 2s linear infinite;
  -webkit-border-top-right-radius: 12px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-topright: 12px;
  -moz-border-radius-bottomright: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  -webkit-border-top-left-radius: 12px;
  -webkit-border-bottom-left-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

/*************************
 Background progress meter
 *************************/
.meter-bkgd {
  height: 20px;
  margin-left: 0;
  position: relative;
  background: #e0e0eb;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 0px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter-bkgd > span {
  display: block;
  height: 100%;
  margin-left: 0;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 0px;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 0px;
  background-color: rgb(250, 176, 60) !important;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #2d4e77),
    color-stop(1, rgb(250, 176, 60))
  ) !important;
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(25, 40, 118) 37%,
    rgb(45, 66, 119) 69%
  ) !important;
  -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}

.meter-bkgd > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ) !important;
  background-image: -webkit-gradient(
    linear,
    0 0,
    100% 100%,
    color-stop(0.25, rgba(255, 255, 255, 0.2)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, transparent),
    to(transparent)
  ) !important;
  background-image: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ) !important;
  z-index: 1;
  -webkit-background-size: 6px 6px;
  -moz-background-size: 6px 6px;
  -webkit-animation: move 2s linear infinite;
  animation: move 2s linear infinite;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 0px;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 0px;
  overflow: hidden;
}

/*******************************************
 UI animations and extra styling:
 *******************************************/
.fade-in,
.fade-out {
  opacity: 0;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.page-fade-in,
.page-fade-out {
  opacity: 0;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.page-fade-in {
  opacity: 1;
}

.slider-wrapper {
  overflow: hidden;
  text-align: center;
  position: relative;
}

.slider-wrapper .contents {
  /*rgba(45,78,119,0.85)*/
  background: rgba(45, 78, 119, 0.92);
  bottom: -100%;
  color: #fab03c;
  height: 100%;
  left: 0%;
  text-align: center;
  position: absolute;
  transition: bottom 0.5s ease;
  width: 100%;
}

.slider-wrapper:hover .contents {
  bottom: 0%;
}

.animated-border {
  background-image: linear-gradient(90deg, #fab03c 50%, #2d4e77 50%),
    linear-gradient(90deg, #fab03c 50%, #2d4e77 50%),
    linear-gradient(0deg, #fab03c 50%, #2d4e77 50%),
    linear-gradient(0deg, #fab03c 50%, #2d4e77 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 100px 4px, 100px 4px, 4px 100px, 4px 100px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 2.5s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }

  100% {
    background-position: left 100px top, right 100px bottom, left bottom 100px,
      right top 100px;
  }
}

.bkgd-progress-bar {
  position: absolute;
  height: 15px;
  width: 100% !important;
  top: 0;
  left: 0;
}

.block-title-bar {
  padding-top: 8px;
  padding-bottom: 12px;
}

.block-title {
  padding-top: 0px;
}

.border-loading {
  border-top: 3px #3e8ed0 solid;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 0;
  background: transparent;
  animation: loading-border 3s linear infinite;
}

@keyframes loading-border {
  from {
    left: 0%;
    width: 0;
    z-index: 100;
  }

  to {
    left: 0;
    width: 100%;
  }
}

.text-blink {
  text-align: center;
  /*margin-bottom: 4px;*/
  /*font-size: 24px;*/
  border-radius: 5px;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
  /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out;
  /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out;
  /* IE */
  animation: blink normal 1.5s infinite ease-in-out;
  /* Opera */
}

.text-blink-left-align {
  text-align: left;
  /*margin-bottom: 4px;*/
  /*font-size: 24px;*/
  border-radius: 5px;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
  /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out;
  /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out;
  /* IE */
  animation: blink normal 1.5s infinite ease-in-out;
  /* Opera */
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  0% {
    background-color: rgba(0, 0, 0, 1);
  }

  50% {
    background-color: rgba(0, 0, 0, 0.5);
  }

  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}

.animated-border-top {
  background-image: linear-gradient(90deg, #fab03c 50%, #2d4e77 50%),
    linear-gradient(90deg, #fab03c 50%, #2d4e77 50%),
    linear-gradient(0deg, #fab03c 50%, #2d4e77 50%),
    linear-gradient(0deg, #fab03c 50%, #2d4e77 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 100px 4px, 0 0, 0 0, 0 0;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 2.5s infinite linear;
}

@keyframes border-dance-top {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }

  100% {
    background-position: left 100px top, right 100px bottom, left bottom 100px,
      right top 100px;
  }
}

/************************************************************
 * Create Animation button glow effect:
 ************************************************************/
.glow-on-hover {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 4px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #fab03c,
    #090979,
    #121a78,
    #1a2a78,
    #2d4e77,
    #1a2a78,
    #121a78,
    #090979,
    #fab03c
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(10px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 10s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 4px;
}

.glow-on-hover:active {
  color: #2d4e77;
}

.glow-on-hover:active:after {
  /*background: #fab03c;*/
  -webkit-animation: bkgd-fade-in-out 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: bkgd-fade-in-out 1s;
  /* Firefox < 16 */
  -ms-animation: bkgd-fade-in-out 1s;
  /* Internet Explorer */
  -o-animation: bkgd-fade-in-out 1s;
  /* Opera < 12.1 */
  animation: bkgd-fade-in-out 1s;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fab03c;
  left: 0;
  top: 0;
  border-radius: 4px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

/************************************************************
 * Create Animation button icon slide
 ************************************************************/
.action-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.action-btn span:after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  content: "\f061" !important;
  /* Go icon */
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.action-btn:hover span {
  padding-right: 25px;
}

.action-btn:hover span:after {
  opacity: 1;
  right: 0;
}

.action-btn:focus span {
  padding-right: 25px;
}

.action-btn:focus span:after {
  opacity: 1;
  right: 0;
}

/************************************************************
 * Discard Job button icon slide
 ************************************************************/
.discard-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.discard-btn span:after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  content: "\f00d" !important;
  /* remove/close icon */
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.discard-btn:hover span {
  padding-right: 25px;
}

.discard-btn:hover span:after {
  opacity: 1;
  right: 0;
}

.discard-btn:focus span {
  padding-right: 25px;
}

.discard-btn:focus span:after {
  opacity: 1;
  right: 0;
}

/************************************************************
 * Discard button (no outline) icon slide
 ************************************************************/
.discard-btn-no-outline span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.discard-btn-no-outline span:after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  content: "\f00d" !important;
  /* remove/close icon */
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.discard-btn-no-outline:hover span {
  padding-right: 25px;
}

.discard-btn-no-outline:hover span:after {
  opacity: 1;
  right: 0;
}

.discard-btn-no-outline:focus span {
  padding-right: 25px;
}

.discard-btn-no-outline:focus span:after {
  opacity: 1;
  right: 0;
}

/************************************************************
 * Remove Character button icon slide
 ************************************************************/
.remove-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.remove-btn span:after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  content: "\f00d" !important;
  /* remove/close icon */
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.remove-btn:hover span {
  padding-right: 25px;
}

.remove-btn:hover span:after {
  opacity: 1;
  right: 0;
}

.remove-btn:focus span {
  padding-right: 25px;
}

.remove-btn:focus span:after {
  opacity: 1;
  right: 0;
}

/************************************************************
 * Action Button Dark with Arrow icon
 ************************************************************/
.action-btn-dark span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.action-btn-dark span:after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  content: "\f061" !important;
  /* Go icon */
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.action-btn-dark:hover span {
  padding-right: 25px;
}

.action-btn-dark:hover span:after {
  opacity: 1;
  right: 0;
}

.action-btn-dark:focus span {
  padding-right: 25px;
}

.action-btn-dark:focus span:after {
  opacity: 1;
  right: 0;
}

.anim-fadein {
  -webkit-animation: fadein 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.5s;
  /* Internet Explorer */
  -o-animation: fadein 0.5s;
  /* Opera < 12.1 */
  animation: fadein 0.5s;
}

#anim-fadein {
  -webkit-animation: fadein 0.1907s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.1907s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.1907s;
  /* Internet Explorer */
  -o-animation: fadein 0.1907s;
  /* Opera < 12.1 */
  animation: fadein 0.1907s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes bkgd-fade-in-out {
  from {
    background: transparent;
  }

  to {
    background: #fab03c;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.menu-grow {
  animation: grow 0.25s;
}

@keyframes grow {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.menu-shrink {
  animation: shrink 0.25s;
}

@keyframes shrink {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

/****** New Walkthrough CSS *******/

.walkthrough-tile-bkgd {
  background: rgba(0, 0, 0, 0.2) !important;
}

.walkthrough-tile-bkgd::before {
  background: transparent !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) !important;
}

/****** Slider CSS *******/
.horizontal-slider {
  width: 100%;
  max-width: var(--slider-width);
  height: 0vh;
  margin: 0;
}

.slider-thumb {
  cursor: pointer;
  position: relative;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #485fc7;
  border-radius: 20%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.slider-thumb.active {
  background-color: #ffffff;
}

.slider-track {
  position: relative;
  background: #ddd;
}

.horizontal-slider .slider-track {
  top: 20px;
  height: 2px;
}

.horizontal-slider .slider-thumb {
  top: 15px;
  width: var(--slider-thumb-width);
  outline: none;
}

// Video dashboard

.video-viewer {
  .ReactCrop {
    cursor: default;
  }

  .ReactCrop:not(.ReactCrop--disabled) .ReactCrop__crop-selection {
    overflow: hidden;
    pointer-events: none !important;
    border: 5px solid #fab03c;
    cursor: none;
  }

  .ReactCrop__drag-handle {
    position: relative;
    display: none;
    cursor: default;
  }

  .ReactCrop__drag-handle::after {
    cursor: none;
  }
}

// use select
.none-select {
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//
div.btn-disabled {
  background-color: #fff;
  border-color: #dbdbdb;
  box-shadow: none;
  opacity: 0.5;
}

// multi person：tip for change root at origin
.multiPerson {
  &.unsupport {
    .dm-white {
      color: #f55e19;
    }
    .toggle-checkbox:checked + .toggle-switch {
      background: #f55e19;
    }
  }
}
// buildAnimationJobConfigScreen
.JobConfigScreen {
  .button-first {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .button-last {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.JobSettingCreditsLabeling {
  color: #5da77c;
  .creditsUsed {
    color: #df546a;
  }
}

.jobUserModel {
  max-width: 200px;
  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
