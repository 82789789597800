.is-16by9-special {
  margin-top: 22%;
}

.selector-inactive {
  color: white !important;
}

.inactive-figure {
  opacity: 0.5;
  border: 2px solid #243e5f;
}

.active-figure {
  border: 2px solid #fab03c;
}

.jobtype-switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

.jobtype-switch input {
  opacity: 0;
  height: 0;
  width: 0;
}

.jobtype-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(#2d4e77, #333);
  border-radius: 34px;
  border: 1px solid #567192;
}

.jobtype-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .jobtype-slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}